import React, { useEffect, useRef, useState } from "react";
// node.js library that concatenates classes (strings)

// javascipt plugin for creating charts
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

import { useAdminDashboard } from "shared/utility/services/hooks/dashboard";
import { selectExhibition } from "redux/reducers/authSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { colors } from "variables/charts";
import { useStallsList } from "../../shared/utility/services/hooks/stalls";

import { sortCarets } from "../../shared/utility/helpers";
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div
      className="dataTables_length w-100 d-inline"
      id="datatable-basic_length"
    >
      <label>
        Show{" "}
        <select
          name="datatable-basic_length"
          aria-controls="datatable-basic"
          className="form-control form-control-sm d-inline w-auto mx-1"
          defaultValue={"10"}
          onChange={(e) => onSizePerPageChange(e.target.value)}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const Dashboard = (props) => {
  const selectedExhibition = useSelector(selectExhibition);
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [statsData, setStatsData] = useState([]);
  const [sales_graph, setSalesGraph] = useState({});
  const [salesman_performance, setSalesmanPerformance] = useState([]);
  const [payment_status_count, setPaymentStatusCount] = useState({});
  const [service_payment_status_count, setServicePaymentStatusCount] = useState(
    {},
  );
  const [visitorsData, setVisitorsData] = useState({});
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    BarElement,
    Tooltip,
    Legend,
  );
  const {
    data: dashboardData,
    isLoading: dashboardLoading,
    isError: dashboardIsError,
    error: dashboardError,
    mutate: dashboard,
  } = useAdminDashboard();

  useEffect(() => {
    try {
      if (dashboardData && !dashboardLoading) {
        let cardData = dashboardData?.stall_cards ?? null;
        let a = [];
        a.push({
          total: cardData?.total_stalls,
          value: cardData?.booked_stalls,
          title: "Booked Stalls",
          descriptionValue: `${cardData?.booked_percent?.toFixed(2)}%`,
          description: "Booked Percentage",
          icon: "fa-solid fa-shop",
        });
        a.push({
          total: cardData?.total_stalls,
          value: cardData?.on_hold_stalls,
          title: "On Hold Stalls",
          descriptionValue: `${cardData?.on_hold_percent?.toFixed(2)}%`,
          description: "On Hold Percentage",
          icon: "fa-solid fa-shop",
        });
        a.push({
          total: cardData?.total_sqm,
          value: cardData?.booked_sqm,
          title: "Booked Sq/M",
          descriptionValue: `${cardData?.booked_percent_sqm?.toFixed(2)}%`,
          description: "Booked Sq/M Percentage",
          icon: "fa-solid fa-chart-area",
        });
        a.push({
          total: cardData?.total_sqm,
          value: cardData?.on_hold_sqm,
          title: "On Hold Sq/M",
          descriptionValue: `${cardData?.on_hold_percent_sqm?.toFixed(2)}%`,
          description: "On Hold Sq/M Percentage",
          icon: "fa-solid fa-chart-area",
        });
        setStatsData(a);
        setSalesGraph(dashboardData?.sales_graph);
        setSalesmanPerformance(dashboardData?.salesman_performance);
        setPaymentStatusCount(dashboardData?.payment_graphs);
        setServicePaymentStatusCount(
          dashboardData?.service_payment_status_count,
        );
        setVisitorsData(dashboardData?.visitors);
      }
      if (dashboardIsError) {
      }
    } catch (err) {
      console.log("err", err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData, dashboardLoading, dashboardIsError, dashboardError]);

  useEffect(() => {
    if (!dashboardLoading && selectedExhibition?.id) {
      dashboard({ exhibition_id: selectedExhibition?.id });
      stalls({ exhibition_id: selectedExhibition?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExhibition]);

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };

  const navigate = useNavigate();
  const createExhibition = () => {
    navigate("/admin/exhibition", { state: { add: true } });
  };

  function generateDateLabels(startDate, endDate) {
    var start = new Date(startDate);
    var end = new Date(endDate);
    var dateArray = [];
    while (start <= end) {
      dateArray.push(new Date(start).toISOString().split("T")[0]); // Format the date to 'YYYY-MM-DD'
      start.setDate(start.getDate() + 1); // Increment the date
    }
    return dateArray;
  }

  let mergedBookingDates = Object.keys(sales_graph);
  let sale_booked_data = mergedBookingDates?.map((val, index) => {
    let counter = 0;
    sales_graph[val].map((item) => {
      if (item.state === "filled") {
        counter = counter + 1;
      }
    });
    return counter;
  });

  let sale_hold_data = mergedBookingDates?.map((val, index) => {
    let counter = 0;
    sales_graph[val].map((item) => {
      if (item.state === "on_hold") {
        counter = counter + 1;
      }
    });
    return counter;
  });

  const report_chart = {
    data: {
      labels: mergedBookingDates,
      datasets: [
        {
          label: "Booked",
          backgroundColor: colors.theme["success"],
          data: sale_booked_data,
          maxBarThickness:
            200 / mergedBookingDates.length <= 10
              ? 10
              : 200 / mergedBookingDates.length,
        },
        {
          label: "Hold",
          backgroundColor: colors.theme["danger"],
          data: sale_hold_data,
          maxBarThickness:
            200 / mergedBookingDates.length <= 10
              ? 10
              : 200 / mergedBookingDates.length,
        },
      ],
    },
    options: {
      borderRadius: 20,
      tooltips: {
        mode: "index",
        intersect: false,
      },
      responsive: true,

      scales: {
        x: {
          stacked: false,
          label: {
            color: "#fff",
          },
        },
        y: {
          stacked: false,
        },
      },
    },
  };

  console.log(sale_booked_data, "sale_booked_data");
  console.log(sale_hold_data, "sale_hold_data");

  let mergedPerformanceDates = Object.keys(payment_status_count);

  let approved_performance_data = mergedPerformanceDates?.map((val, index) => {
    let day_total = 0;
    payment_status_count[val].map((item) => {
      if (item.status === "approved") {
        day_total = day_total + item.amount_paid;
      }
    });
    return day_total;
  });
  let pending_performance_data = mergedPerformanceDates?.map((val, index) => {
    let day_total = 0;
    payment_status_count[val].map((item) => {
      if (item.status === "pending") {
        day_total = day_total + item.amount_paid;
      }
    });
    return day_total;
  });
  console.log(approved_performance_data, "approved_performance_data");
  const LineChartOptions = {
    responsive: true,
    borderJoinStyle: "round",
    plugins: {
      legend: {
        position: "top",
        // color: "#fff",
        /* labels: {
          color: colors.theme.secondary,
        },*/
      },

      title: {
        display: true,
        text: "Sales Report",
        // color: "#fff",
      },
    },
  };

  const componentRef1 = useRef(null);
  const componentRef2 = useRef(null);
  const componentRef3 = useRef(null);
  const componentRef4 = useRef(null);

  const salesmanTableData = salesman_performance?.map((item) => {
    let bookedArea = item.booked_stalls.reduce((acc, stall) => {
      return acc + stall.length + stall.width;
    }, 0);
    let bookedAmount = item.booked_stalls.reduce((acc, stall) => {
      return acc + stall.length * stall.width * stall.price_per_sq_unit;
    }, 0);
    return {
      name: item?.salesman.name,
      bookedStallsQty: item?.booked_stalls?.length,
      holdStallsQty: item?.on_hold_stalls?.length,
      bookedArea: bookedArea,
      bookedAmount: bookedAmount,
    };
  });

  const {
    data: stallsData,
    isLoading: stallsLoading,
    isError: stallsIsError,
    error: stallsError,
    mutate: stalls,
  } = useStallsList();
  const [bookedStalls, setBookedStalls] = useState([]);
  const [onHoldStalls, setOnHoldStalls] = useState([]);
  const [allOkStalls, setAllOkStalls] = useState([]);
  useEffect(() => {
    if (stallsData && !stallsLoading) {
      let booked = stallsData?.results.filter(
        (stall) => stall?.state === "filled",
      );
      let onHold = stallsData?.results.filter(
        (stall) => stall?.state === "on_hold",
      );
      let allOk = stallsData?.results.filter(
        (stall) => stall?.stall_status === "all_ok",
      );
      console.log(booked, onHold, allOk, "stallsData");
      setBookedStalls(booked);
      setOnHoldStalls(onHold);
      setAllOkStalls(allOk);
    }
  }, [stallsData, stallsLoading, stallsIsError, stallsError]);

  return (
    <>
      {/* <Header /> */}
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {statsData?.map((data) => {
                return (
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <Col>
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 text-nowrap"
                            >
                              {data?.title}
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0 text-nowrap">
                              {data?.value}/{data?.total}
                            </span>
                          </Col>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className={data?.icon ?? "fas fa-chart-bar"} />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm row">
                          <span className=" mr-2  col-4 text-success">
                            {/* <i className="fa fa-arrow-up" />  */}
                            {data?.descriptionValue}
                          </span>
                          <span className="text-nowrap col-8">
                            {data?.description}
                          </span>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}

              {!selectedExhibition && (
                <Col lg={12}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <div
                        className="d-flex justify-content-center align-items-center flex-column"
                        style={{ height: "50vh" }}
                      >
                        <div>
                          <h3 className="font-weight-bold mb-4 text-center">
                            No Exhibition Created
                          </h3>
                        </div>

                        <div>
                          <Button
                            color={"primary"}
                            onClick={createExhibition}
                            size="md"
                          >
                            Create Exhibition
                          </Button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      {selectedExhibition && (
        <Container className="mt--7" fluid>
          <Row className={"mx-n2 my-n2"}>
            <Col className={"px-2 py-2"} xl="6">
              <Card className=" shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                      <h2 className=" mb-0">Payment Collection Report</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart ">
                    <Line
                      className={"text-white"}
                      data={{
                        labels: mergedPerformanceDates,
                        datasets: [
                          {
                            label: "Approved Payments",
                            data: approved_performance_data,
                            borderColor: "#1eff00",
                            tension: 0.3,
                          },
                          {
                            label: "Pending Payments",
                            data: pending_performance_data,
                            borderColor: "#ff0016",
                            tension: 0.3,
                          },
                        ],
                      }}
                      options={LineChartOptions}
                      getDatasetAtEvent={(e) => console.log(e)}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col className="" xl="4">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Payment Pending Report</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={chartExample1[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>*/}
            <Col className={"px-2 py-2"} xl="6">
              <Card className="shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Stalls report</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart p-3">
                    <Bar
                      data={report_chart.data}
                      options={report_chart.options}
                      className="chart-canvas"
                      id="chart-bar-stacked"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className={"px-2 py-2 "} xl="6">
              <Card className="shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Salesman</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    data={salesmanTableData}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "bookedStallsQty",
                        text: "Booked Stalls",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: false,
                      },
                      {
                        dataField: "holdStallsQty",
                        text: "On Hold Stalls",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "bookedAmount",
                        text: "Booking Amount",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "bookedArea",
                        text: "Booked Area",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef1}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className={"px-2 py-2"} xl="6">
              <Card className="shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Stalls on Hold</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    data={onHoldStalls}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "hall_or_dome",
                        text: "Hall",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: false,
                      },
                      {
                        dataField: "on_hold_date",
                        text: "On Hold Date",
                        formatter: (cell) => {
                          return new Date(cell).toLocaleDateString();
                        },
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },

                      {
                        dataField: "status_pill",
                        text: "Status",

                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef2}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className={"px-2 py-2 "} xl="6">
              <Card className="shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">All payment done stalls</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    data={allOkStalls}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "hall_or_dome",
                        text: "Hall",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: false,
                      },
                      {
                        dataField: "booking_date",
                        text: "Booked Date",
                        formatter: (cell) => {
                          return new Date(cell).toLocaleDateString();
                        },
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },

                      {
                        dataField: "user.brand_name",
                        text: "User",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef1}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
            <Col className={"px-2 py-2"} xl="6">
              <Card className="shadow h-100">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Performance
                      </h6>
                      <h2 className="mb-0">Booked Stalls</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <ToolkitProvider
                    data={bookedStalls}
                    keyField="id"
                    columns={[
                      {
                        dataField: "name",
                        text: "Name",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },
                      {
                        dataField: "hall_or_dome",
                        text: "Hall",
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        searchable: false,
                      },
                      {
                        dataField: "booking_date",
                        text: "Booked Date",
                        formatter: (cell) => {
                          return new Date(cell).toLocaleDateString();
                        },
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                      },

                      {
                        dataField: "user.brand_name",
                        text: "User",
                        searchable: false,
                        sort: true,
                        sortCaret: (order, column) => sortCarets(order, column),
                        headerStyle: { textAlign: "center" },
                        style: {
                          textAlign: "center",
                        },
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}></Col>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter px-4 pb-1 float-right"
                              >
                                <label>
                                  <span>Search:</span>
                                  <SearchBar
                                    className="form-control-sm ml-2"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef1}
                          {...props.baseProps}
                          bootstrap4={true}
                          classes={"mb-3"}
                          pagination={pagination}
                          bordered={true}
                          hover
                          condensed
                          rowStyle={{ textAlign: "center" }}
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
