import React from "react";
import { useLocation, Route, Routes } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

import PublicNavbar from "components/Navbars/PublicNavbar.js";

import { myPublicRoutes as routes } from "routes.js";

const Public = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/public") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  const getQueryParam = (param) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(param);
  };
  const exb_id = getQueryParam("exb");
  console.log(exb_id);
  const [registerExb, setRegisterExb] = React.useState();
  if (exb_id) {
    setRegisterExb(exb_id);
  }
  return (
    <>
      <div className="main-content" ref={mainContent}>
        <PublicNavbar exb_details={exb_id} />
        <div className="header bg-gradient-info py-6 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  {location.pathname === "/pb/register" && (
                    <p className="text-lead text-light">
                      Register to get started
                    </p>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Routes>{getRoutes(routes)}</Routes>
          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Public;
