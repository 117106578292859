import { useMutation } from 'react-query';
import useAxios from '../useAxios';
import { dashboardRoutes } from './route';
import { objectToQueryString } from 'shared/utility/helpers';

const {
  dashboard,
} = dashboardRoutes;

export const useAdminDashboard = () => {
  const callApi = useAxios();
  const { url, method } = dashboard.get;

  return useMutation((data) => {
    const queryString = objectToQueryString(data);

    let newUrl = `${url}?${queryString}`
    return callApi({
      method: method,
      url: newUrl,
    }).then((data) => data
    );
  });
};
